import React from "react";
import SelfLearning from "./components/SelfLearning";
import Discussions from "./components/Discussions";
import Quiz from "./components/Quiz";
// import { List } from "@instructure/ui-list";
import { Text } from "@instructure/ui-text";
import { View } from "@instructure/ui-view";
import Courseware from "./components/Courseware";
import Observer from "./components/Observer";

class ShowDTI extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      courses: [],
      teacher_name: "",
    };
  }

  hideTray = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    return (
      <> 
      <View as="div" margin="small">
        <Text size="large">2.4 (1) Giáo viên giao bài cho học sinh tự học</Text>
        <SelfLearning caption={"Giáo viên giao bài cho học sinh tự học"} />
      </View>
      <View as="div" margin="small">
        <Text size="large">
          2.4 (2) Giáo viên trả lời (giải đáp) các câu hỏi của học sinh
        </Text>
        <Discussions
          caption={"Giáo viên trả lời (giải đáp) các câu hỏi của học sinh"}
        />
      </View>
      <View as="div" margin="small">
        <Text size="large">2.4 (3) Tổ chức kiểm tra đánh giá thường xuyên</Text>
        <Quiz caption={"Tổ chức kiểm tra đánh giá thường xuyên"} />
      </View>
        <View as="div" margin="small">
          <Text size="large">2.4 (4) Phụ huynh học sinh tham gia vào hoạt động học tập của học sinh</Text>
          <Observer caption={"Phụ huynh học sinh tham gia vào hoạt động học tập của học sinh"} />
        </View>
        <View as="div" margin="small">
          <Text size="large">2.5 Số lượng học liệu được số hóa</Text>
          <Courseware caption={"Số lượng học liệu được số hóa"} />
        </View>
      </>
     
    );
  }
}

export default ShowDTI;
