import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import SelfLearning from "./components/SelfLearning";
import Discussions from "./components/Discussions";
import Quiz from "./components/Quiz";
import { View } from "@instructure/ui-view";
import { Text } from "@instructure/ui-text";
import Courseware from "./components/Courseware";
import Observer from "./components/Observer";

export default function Iframe() {
  let { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [token, setToken] = useState(null);
  useEffect(() => {
    const jwtoken = searchParams.get("token");
    if (jwtoken) {
      setToken(jwtoken);
      localStorage.setItem('token', jwtoken);
    } else {
      const jwtLocal = localStorage.getItem('token');
      if (jwtLocal) {
        setToken(jwtLocal);
      } else {
        setToken(null);
        localStorage.clear();
      }
    }
  }, []);

  const renderIframe = () => {
    if (token) {
      if (id === "self-learning") {
        return (
          <View as="div" margin="small">
            <Text size="large">Giáo viên giao bài cho học sinh tự học</Text>
            <SelfLearning
              iframe={true}
              caption={"Giáo viên giao bài cho học sinh tự học"}
            />
          </View>
        );
      } else if (id === "quiz") {
        return (
          <View as="div" margin="small">
            <Text size="large">Tổ chức kiểm tra đánh giá thường xuyên</Text>
            <Quiz
              iframe={true}
              caption={"Tổ chức kiểm tra đánh giá thường xuyên"}
            />
          </View>
        );
      } else if (id === "discussions") {
        return (
          <View as="div" margin="small">
            <Text size="large">
              Giáo viên trả lời (giải đáp) các câu hỏi của học sinh
            </Text>
            <Discussions
              iframe={true}
              caption={"Giáo viên trả lời (giải đáp) các câu hỏi của học sinh"}
            />
          </View>
        );
      } else if (id === "observer") {
        return (
          <View as="div" margin="small">
            <Text size="large">
              Phụ huynh học sinh tham gia vào hoạt động học tập của học sinh
            </Text>
            <Observer
              iframe={true}
              caption={
                "Phụ huynh học sinh tham gia vào hoạt động học tập của học sinh"
              }
            />
          </View>
        );
      } else if (id === "courseware") {
        return (
          <View as="div" margin="small">
            <Text size="large">Số lượng học liệu được số hóa</Text>
            <Courseware iframe={true} caption={"Số lượng học liệu được số hóa"} />
          </View>
        );
      } else {
        return (
          <View as="div" margin="small" textAlign="center">
            <Text size="large">Không tìm thấy đường dẫn</Text>
          </View>
        );
      }
    } else {
      return (
        <View as="div" margin="small" textAlign="center">
          <Text size="large">Bạn không được quyền truy cập</Text>
        </View>
      );
    }
    
  };
  return renderIframe();
}
