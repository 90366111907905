
import React from "react";
import { Pagination } from '@instructure/ui-pagination'
import TeacherTable from './TeacherTable'

class PaginatedTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
    }
  }

  handleClick = (page) => {
    this.setState({
      page,
    })
  }

  handleSort = (event, options) => {
    const { onSort } = this.props

    this.setState({
      page: 0,
    })
    onSort(event, options)
  }



  render() {
    const { caption, headers, rows, sortBy, ascending, perPage, lms_domain} = this.props
   
    const { page } = this.state
    const startIndex = page * perPage
    const slicedRows = rows.slice(startIndex, startIndex + perPage)
    const pageCount = perPage && Math.ceil(rows.length / perPage)
    return (
      <div>
        <TeacherTable
          caption={caption}
          headers={headers}
          rows={slicedRows}
          onSort={this.handleSort}
          sortBy={sortBy}
          ascending={ascending}
          rowIds={rows.map((row) => row.id)}
          lms_domain={lms_domain}
        />
        {pageCount > 1 && (
          <Pagination
            variant='compact'
            labelNext='Tiếp theo'
            labelPrev='Trước'
            margin='large'
          >
            {Array.from(Array(pageCount), (item, index) => (
              <Pagination.Page
                key={index}
                onClick={() => this.handleClick(index)}
                current={index === page}
              >
                {index + 1}
              </Pagination.Page>
            ))}
          </Pagination>
        )}
        
      </div>
    )
  }
}
export default PaginatedTable;