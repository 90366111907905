import React, { useEffect, useState } from "react";
import { Table } from "@instructure/ui-table";
import PaginatedTable from "../common/PaginatedTable";
import DTIPages from "../common/DTIPages";
import { Button } from "@instructure/ui-buttons";
import { View } from "@instructure/ui-view";
import { Text } from "@instructure/ui-text";
import {
  getJobCourseware,
  getCourseware,
  createJobCourseware,
  postCourseware
} from "../api/api";
import { t } from "../ultils/translates";
import { Spinner } from "@instructure/ui-spinner";

export default function Courseware({
  caption,
  iframe = false,
  layout = "auto",
}) {
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [headers, setHeaders] = useState([
    {
      id: "stt",
      text: t("STT"),
      width: "5%",
      textAlign: "start",
    },
    {
      id: "name",
      text: t("Tên khóa học"),
      width: "10%",
      textAlign: "start",
    },
    {
      id: "totalDocument",
      text: t("totalDocument"),
      width: "10%",
      textAlign: "center",
    },
    {
      id: "totalVideo",
      text: t("totalVideo"),
      width: "10%",
      textAlign: "center",
    },
    {
      id: "totalAudio",
      text: t("totalAudio"),
      width: "10%",
      textAlign: "center",
    },
    {
      id: "totalImage",
      text: t("totalImage"),
      width: "10%",
      textAlign: "center",
    },
    {
      id: "totalEmbedContent",
      text: t("totalEmbedContent"),
      width: "10%",
      textAlign: "center",
    },
    {
      id: "totalOtherCourseware",
      text: t("totalOtherCourseware"),
      width: "10%",
      textAlign: "center",
    },
  ]);
  const [rows, setRows] = useState([]);
  const [demoHeaders, setDemoHeaders] = useState([
    {
      id: "totalDocument",
      text: t("totalDocument"),
      width: "16,67%",
      textAlign: "center",
    },
    {
      id: "totalAudio",
      text: t("totalAudio"),
      width: "16,67%",
      textAlign: "center",
    },
    {
      id: "totalVideo",
      text: t("totalVideo"),
      width: "16,67%",
      textAlign: "center",
    },
    {
      id: "totalImage",
      text: t("totalImage"),
      width: "16,67%",
      textAlign: "center",
    },
    // {
    //   id: "totalEmbedContent",
    //   text: t("totalEmbedContent"),
    //   width: "20%",
    //   textAlign: "center",
    // },
    {
      id: "totalOtherCourseware",
      text: t("totalOtherCourseware"),
      width: "16,67%",
      textAlign: "center",
    },
    {
      id: "totalCoursewareQuestion",
      text: t("totalCoursewareQuestion"),
      width: "16,67%",
      textAlign: "center",
    },
  ]);
  const [demoRows, setDemoRows] = useState([]);
  const [ascending, setAscending] = useState("ascending");
  useEffect(() => {
    const fetchData = async () => {
      const groupedData = {};
      const dataCourseware = await getCourseware();
      setDemoRows([
        {
          totalDocument: dataCourseware.totalDocument,
          totalVideo: dataCourseware.totalVideo,
          totalAudio: dataCourseware.totalAudio,
          totalImage: dataCourseware.totalImage,
          totalEmbedContent: dataCourseware.totalEmbedContent,
          totalOtherCourseware: dataCourseware.totalOtherCourseware,
          totalCoursewareQuestion: dataCourseware.totalCoursewareQuestion
        },
      ]);
      function addToGroupedData(item, key) {
        const { id, name, count } = item;
        if (!groupedData[id]) {
          groupedData[id] = {
            stt: Object.keys(groupedData).length + 1,
            id,
            name,
            totalDocument: 0,
            totalVideo: 0,
            totalAudio: 0,
            totalImage: 0,
            totalEmbedContent: 0,
            totalOtherCourseware: 0,
            totalCoursewareQuestion: 0
          };
        }
        groupedData[id][key] += parseInt(count, 10);
      }

      // Function to group and count by id and name
      function groupAndCountByIdAndName(data) {
        const grouped = {};

        // Iterate over the dataQuestionChoice array
        data.forEach((item) => {
          const { id, name } = item;

          // If the id is not already in the groupedData object, add it
          if (!grouped[id]) {
            grouped[id] = {
              id,
              name,
              count: 0,
            };
          }

          // Increment the count for the id
          grouped[id].count += 1;
        });

        // Convert the groupedData object to an array
        return Object.values(grouped);
      }

      // Group and count the data
      dataCourseware.dataDocument = groupAndCountByIdAndName(dataCourseware.dataDocument);
      dataCourseware.dataVideo = groupAndCountByIdAndName(dataCourseware.dataVideo);
      dataCourseware.dataAudio = groupAndCountByIdAndName(dataCourseware.dataAudio);
      dataCourseware.dataImage = groupAndCountByIdAndName(dataCourseware.dataImage);
      dataCourseware.dataEmbedContent = groupAndCountByIdAndName(dataCourseware.dataEmbedContent);
      dataCourseware.dataOtherCourseware = groupAndCountByIdAndName(dataCourseware.dataOtherCourseware);
      dataCourseware.dataCoursewareQuestion = groupAndCountByIdAndName(dataCourseware.dataCoursewareQuestion);

      dataCourseware.dataDocument.forEach((item) => {
        addToGroupedData(item, "totalDocument");
      });

      // Process dataVideo
      dataCourseware.dataVideo.forEach((item) => {
        addToGroupedData(item, "totalVideo");
      });

      // Process dataAudio
      dataCourseware.dataAudio.forEach((item) => {
        addToGroupedData(item, "totalAudio");
      });

      // Process dataImage
      dataCourseware.dataImage.forEach((item) => {
        addToGroupedData(item, "totalImage");
      });

      // Process dataEmbedContent
      dataCourseware.dataEmbedContent.forEach((item) => {
        addToGroupedData(item, "totalEmbedContent");
      });

      // Process dataOtherCourseware
      dataCourseware.dataOtherCourseware.forEach((item) => {
        addToGroupedData(item, "totalOtherCourseware");
      });

      dataCourseware.dataCoursewareQuestion.forEach((item) => {
        addToGroupedData(item, "totalCoursewareQuestion");
      });

      setRows(Object.values(groupedData));
      const job = await getJobCourseware();
      if (["starting", "pending"].includes(job?.workflow_state)) {
        setIsLoading(true);
        var intervalId = setInterval(async () => {
          const job = await getJobCourseware();
          if (job?.workflow_state === "done") {
            setIsLoading(false);
            clearInterval(intervalId);
          }
        }, 1000);
      }
    };
    fetchData();
  }, [isLoading]);
  const handleSort = (event, options) => {
    const { onSort } = this.state;

    this.setState({
      page: 0,
    });
    onSort(event, options);
  };

  const handleSubmit = async () => {
    setIsSubmit(true);
    const data = await postCourseware();
    setIsSubmit(false);
    setSuccess(true);
    const date = new Date(data.updatedAt);

    const options = {
      timeZone: 'Asia/Ho_Chi_Minh',
      hour: '2-digit',
      minute: '2-digit',
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour12: false, // Use 24-hour format
    };
    
    // Use Intl.DateTimeFormat to format the date according to the specified options
    const formatter = new Intl.DateTimeFormat('en-GB', options);
    const [
      { value: day },, // Skips the comma
      { value: month },, // Skips the comma
      { value: year },, // Skips the comma
      { value: hours },, // Skips the comma
      { value: minutes }
    ] = formatter.formatToParts(date);
    
    // Format the date as HH:mm DD/MM/YYYY
    const formattedDate = `${hours}:${minutes} ${day}/${month}/${year}`;
    setMessage(`vào ${formattedDate}`)
  };
  const handleJob = async () => {
    if (!isLoading) {
      setIsLoading(true);
      await createJobCourseware();
      var intervalId = setInterval(async () => {
        const job = await getJobCourseware();
        if (job?.workflow_state === "done") {
          setIsLoading(false);
          clearInterval(intervalId);
        }
      }, 1000);
    }
  };
  return (
    <>
      {iframe ? (
        <PaginatedTable
          headers={headers}
          rows={rows}
          sortBy={headers && headers[0]?.id}
          onSort={handleSort}
          ascending={ascending}
          perPage={15}
        />
      ) : (
        <>
          <View as="div" display="inline-block" textAlign="end">
            <Button
              onClick={handleSubmit}
              withBackground={false}
              color="primary"
              size="small"
              margin="small"
            >
              {isSubmit ? (
                <Spinner renderTitle="Đang tải" size="x-small" />
              ) : (
                "Nộp báo cáo DTI"
              )}
            </Button>
            <Text>
              {success ? <><b style={{ color: "green" }}>Đã nộp</b> vào {message}</> : <></>}
            </Text>
          </View>

          <View as="div">
            <Text size="medium">
              Dữ liệu trước khi nộp báo cáo DTI cho <b>{caption}</b>
            </Text>
            <Button
              onClick={handleJob}
              withBackground={false}
              color="primary"
              size="small"
              margin="small"
            >
              {isLoading ? (
                <Spinner renderTitle="Đang tải" size="x-small" />
              ) : (
                "Làm mới"
              )}
            </Button>
          </View>

          <DTIPages
            secondPage={
              <PaginatedTable
                headers={headers}
                rows={rows}
                sortBy={headers && headers[0]?.id}
                onSort={handleSort}
                ascending={ascending}
                perPage={10}
              />
            }
            firstPage={
              <Table caption={caption}>
                <Table.Head>
                  <Table.Row>
                    {(demoHeaders || []).map(
                      ({ id, text, width, textAlign }) => (
                        <Table.ColHeader
                          key={id}
                          id={id}
                          width={width}
                          textAlign={textAlign}
                        >
                          {text}
                        </Table.ColHeader>
                      )
                    )}
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  {demoRows.map((row) => (
                    <Table.Row key={row.id}>
                      {demoHeaders.map(({ id, renderCell, textAlign }) => (
                        <Table.Cell
                          key={id}
                          textAlign={layout === "stacked" ? "start" : textAlign}
                        >
                          {renderCell ? renderCell(row[id]) : row[id]}
                        </Table.Cell>
                      ))}
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            }
          />
        </>
      )}
    </>
  );
}
