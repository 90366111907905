import React from "react";
import { Grid } from '@instructure/ui-grid'
import { Heading } from '@instructure/ui-heading'
import { IconDownloadLine } from '@instructure/ui-icons'
import { Link } from "@instructure/ui-link";

class Export extends React.Component {
  constructor (props) {
    super(props)
  }

  render(){
    return (
      <Grid startAt="medium" vAlign="middle" colSpacing="small">
        <Grid.Row>
          <Grid.Col width="auto">
          <Heading margin='0 0 small 0'>Thống kê hoạt động của giáo viên</Heading>
          </Grid.Col>
          <Grid.Col textAlign="end">
            <Link href="/api/v1/teachers/exports" target="_blank" renderIcon={IconDownloadLine} withBackground={false} color="primary" margin="small">Xuất báo cáo</Link>
          </Grid.Col>
        </Grid.Row>
      </Grid>
    )
  }      
}

export default Export;