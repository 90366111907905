import React from "react";
import { Table } from '@instructure/ui-table';
import {Responsive} from '@instructure/ui-responsive';
import { ScreenReaderContent } from '@instructure/ui-a11y-content';
import { Link } from '@instructure/ui-link'
import CourseTable from './CourseTable'

class TeacherTable extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      open: false,
      courses: [],
      teacher_name: ""
    }
  }

  hideTray = () => {
    this.setState({
      open: false
    })
  }

  render() {
    const { caption, headers, rows, onSort, sortBy, ascending, lms_domain} = this.props
    const direction = ascending ? 'ascending' : 'descending'

    return (
      <Responsive
        query={{
          small: { maxWidth: '40rem' },
          large: { minWidth: '41rem' },
        }}
        props={{
          small: { layout: 'stacked' },
          large: { layout: 'auto' },
        }}
      >
        {(props) => (
          <div>
            <Table
              margin="auto"
              caption={`${caption}: tìm kiếm theo ${sortBy}`}
              {...props}
            >
              <Table.Head renderSortLabel={<ScreenReaderContent>Sort by</ScreenReaderContent>}>
                <Table.Row>
                  {(headers || []).map(({ id, text, width }) => (
                    <Table.ColHeader
                      key={id}
                      id={id}
                      width={width}
                      onRequestSort={onSort}
                      sortDirection={id === sortBy ? direction : 'none'}
                    >
                      {text}
                    </Table.ColHeader>
                  ))}
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {(rows || []).map((row) => {
                  return (
                    <Table.Row key={row.id}>
                      {(headers || []).map(({ id, renderCell }) => (
                        <Table.Cell key={id}>
                          {id == "name" ? 
                            <Link onClick={() => { this.setState({ open: true, courses: row['courses'], teacher_name: row[id]}) }}>
                              {renderCell ? renderCell(row[id]): row[id]}
                            </Link>
                          : renderCell ? renderCell(row[id]) : row[id]}
                        </Table.Cell>
                      ))}
                    </Table.Row>
                  )
                })}
              </Table.Body>
            </Table>
            <CourseTable courses={this.state.courses} teacher_name={this.state.teacher_name} open={this.state.open} onClick={() => this.setState({open: false})} hideTray={this.hideTray} lms_domain={lms_domain}/>
          </div>
        )}
      </Responsive>
    )
  }
}

export default TeacherTable;