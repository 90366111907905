import React, { useEffect, useState } from "react";
import { Table } from "@instructure/ui-table";
import PaginatedTable from "../common/PaginatedTable";
import DTIPages from "../common/DTIPages";
import { Button } from "@instructure/ui-buttons";
import { View } from "@instructure/ui-view";
import { Text } from "@instructure/ui-text";
import {
  getJobObserver,
  getObserver,
  createJobObserver,
  postObserver,
} from "../api/api";
import { t } from "../ultils/translates";
import { Spinner } from "@instructure/ui-spinner";

export default function Observer({ caption, iframe = false, layout = "auto" }) {
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [demoHeaders, setDemoHeaders] = useState([
    {
      id: "totalParent",
      text: t("totalParent"),
      width: "100%",
      textAlign: "center",
    },
  ]);
  const [demoRows, setDemoRows] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const dataObserver = await getObserver();
      setDemoRows([
        {
          totalParent: dataObserver.totalParent,
        },
      ]);
      const job = await getJobObserver();
      if (["starting", "pending"].includes(job?.workflow_state)) {
        setIsLoading(true);
        var intervalId = setInterval(async () => {
          const job = await getJobObserver();
          if (job?.workflow_state === "done") {
            setIsLoading(false);
            clearInterval(intervalId);
          }
        }, 1000);
      }
    };
    fetchData();
  }, [isLoading]);

  const handleSubmit = async () => {
    setIsSubmit(true);
    const data = await postObserver();
    setIsSubmit(false);
    setSuccess(true);
    const date = new Date(data.updatedAt);

    const options = {
      timeZone: "Asia/Ho_Chi_Minh",
      hour: "2-digit",
      minute: "2-digit",
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour12: false, // Use 24-hour format
    };

    // Use Intl.DateTimeFormat to format the date according to the specified options
    const formatter = new Intl.DateTimeFormat("en-GB", options);
    const [
      { value: day }, // Skips the comma
      ,
      { value: month }, // Skips the comma
      ,
      { value: year }, // Skips the comma
      ,
      { value: hours }, // Skips the comma
      ,
      { value: minutes },
    ] = formatter.formatToParts(date);

    // Format the date as HH:mm DD/MM/YYYY
    const formattedDate = `${hours}:${minutes} ${day}/${month}/${year}`;
    setMessage(`vào ${formattedDate}`);
  };
  const handleJob = async () => {
    if (!isLoading) {
      setIsLoading(true);
      await createJobObserver();
      var intervalId = setInterval(async () => {
        const job = await getJobSelfLearning();
        if (job?.workflow_state === "done") {
          setIsLoading(false);
          clearInterval(intervalId);
        }
      }, 1000);
    }
  };
  return (
    <>
      {iframe ? (
        <View as="div" margin="small" textAlign="center">
          <Text size="large">Bạn không được quyền truy cập</Text>
        </View>
      ) : (
        <>
          <View as="div" display="inline-block" textAlign="end">
            <Button
              onClick={handleSubmit}
              withBackground={false}
              color="primary"
              size="small"
              margin="small"
            >
              {isSubmit ? (
                <Spinner renderTitle="Đang tải" size="x-small" />
              ) : (
                "Nộp báo cáo DTI"
              )}
            </Button>
            <Text>
              {success ? (
                <>
                  <b style={{ color: "green" }}>Đã nộp</b> vào {message}
                </>
              ) : (
                <></>
              )}
            </Text>
          </View>
          <View as="div">
            <Text size="medium">
              Dữ liệu trước khi nộp báo cáo DTI cho <b>{caption}</b>
            </Text>
            <Button
              onClick={handleJob}
              withBackground={false}
              color="primary"
              size="small"
              margin="small"
            >
              {isLoading ? (
                <Spinner renderTitle="Đang tải" size="x-small" />
              ) : (
                "Làm mới"
              )}
            </Button>
          </View>

          <Table caption={caption}>
            <Table.Head>
              <Table.Row>
                {(demoHeaders || []).map(({ id, text, width, textAlign }) => (
                  <Table.ColHeader
                    key={id}
                    id={id}
                    width={width}
                    textAlign={textAlign}
                  >
                    {text}
                  </Table.ColHeader>
                ))}
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {demoRows.map((row) => (
                <Table.Row key={row.id}>
                  {demoHeaders.map(({ id, renderCell, textAlign }) => (
                    <Table.Cell
                      key={id}
                      textAlign={layout === "stacked" ? "start" : textAlign}
                    >
                      {renderCell ? renderCell(row[id]) : row[id]}
                    </Table.Cell>
                  ))}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </>
      )}
    </>
  );
}
