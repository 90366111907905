import { get, post, put, remove } from "./utils";


export const postSelfLearning = () => {
  return post(`/api/v1/self-learning`);
};

export const postDiscussion = () => {
  return post(`/api/v1/discussion`);
};

export const postCourseware = () => {
  return post(`/api/v1/courseware`);
};

export const postQuiz = () => {
  return post(`/api/v1/quiz`);
};

export const postObserver = () => {
  return post(`/api/v1/observer`);
};


// GET
export const getSelfLearning = () => {
  return get(`/api/v1/self-learning`);
};

export const getDiscussion = () => {
  return get(`/api/v1/discussion`);
};

export const getCourseware = () => {
  return get(`/api/v1/courseware`);
};

export const getQuiz = () => {
  return get(`/api/v1/quiz`);
};

export const getObserver = () => {
  return get(`/api/v1/observer`);
};


// create job
export const createJobSelfLearning = () => {
  return post(`/api/v1/self-learning/scheduler`);
};

export const createJobDiscussion = () => {
  return post(`/api/v1/discussion/scheduler`);
};

export const createJobQuiz = () => {
  return post(`/api/v1/quiz/scheduler`);
};

export const createJobCourseware = () => {
  return post(`/api/v1/courseware/scheduler`);
};

export const createJobObserver = () => {
  return post(`/api/v1/observer/scheduler`);
};

//get job

export const getJobSelfLearning = () => {
  return get(`/api/v1/self-learning/scheduler`);
};

export const getJobDiscussion = () => {
  return get(`/api/v1/discussion/scheduler`);
};

export const getJobQuiz = () => {
  return get(`/api/v1/quiz/scheduler`);
};

export const getJobCourseware = () => {
  return get(`/api/v1/courseware/scheduler`);
};

export const getJobObserver = () => {
  return get(`/api/v1/observer/scheduler`);
};