
import React from "react";
import { Pagination } from '@instructure/ui-pagination'
import { Table } from '@instructure/ui-table';
import {Responsive} from '@instructure/ui-responsive';
import { ScreenReaderContent } from '@instructure/ui-a11y-content';
import { Link } from '@instructure/ui-link'

class PaginatedTable extends React.Component {
  constructor(props) {
    super(props)
    const { headers } = props
    this.state = {
      page: 0,
      sortBy: headers && headers[0] && headers[0].id,
      ascending: true,
    }
  }

  handleClick = (page) => {
    this.setState({
      page,
    })
  }

  handleSort = (event, { id }) => {
    const { sortBy, ascending } = this.state
    console.log(id, sortBy)
    if (id === sortBy) {
      this.setState({
        ascending: !ascending,
      })
    } else {
      this.setState({
        sortBy: id,
        ascending: true,
      })
    }
  }



  render() {
    const { caption, headers, rows, perPage, layout} = this.props
    const { sortBy, ascending, page } = this.state
    const sortedRows = rows.sort((a, b) => {
      if (a[sortBy] < b[sortBy]) {
        return -1
      }
      if (a[sortBy] > b[sortBy]) {
        return 1
      }
      return 0
    })

    if (!ascending) {
      sortedRows.reverse()
    }
    const startIndex = page * perPage
    const slicedRows = rows.slice(startIndex, startIndex + perPage)
    const pageCount = perPage && Math.ceil(rows.length / perPage)
    const direction = ascending ? 'ascending' : 'descending'
    return (
      <div>
        <Responsive
          query={{
            small: { maxWidth: '40rem' },
            large: { minWidth: '41rem' },
          }}
          props={{
            small: { layout: 'stacked' },
            large: { layout: 'auto' },
          }}
        >
          {(props) => (
            <div>
              <Table
                margin="auto"
                {...props}
              >
                <Table.Head renderSortLabel={<ScreenReaderContent>Sort by</ScreenReaderContent>}>
                  <Table.Row>
                    {(headers || []).map(({ id, text, width }) => (
                      <Table.ColHeader
                        key={id}
                        id={id}
                        width={width}
                        onRequestSort={this.handleSort}
                        sortDirection={id === sortBy ? direction : 'none'}
                      >
                        {text}
                      </Table.ColHeader>
                    ))}
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  {(slicedRows || []).map((row) => {
                    return (
                      <Table.Row key={row.id}>
                         {(headers || []).map(({ id, renderCell, textAlign }) => (
                            <Table.Cell key={id} textAlign={layout === 'stacked' ? 'start' : textAlign}>
                              {renderCell ? renderCell(row[id]) : row[id]}
                            </Table.Cell>
                          ))}
                      </Table.Row>
                    )
                  })}
                </Table.Body>
              </Table>
            </div>
          )}
        </Responsive>
        {pageCount > 1 && (
          <Pagination
            variant='compact'
            labelNext='Tiếp theo'
            labelPrev='Trước'
            margin='large'
          >
            {Array.from(Array(pageCount), (item, index) => (
              <Pagination.Page
                key={index}
                onClick={() => this.handleClick(index)}
                current={index === page}
              >
                {index + 1}
              </Pagination.Page>
            ))}
          </Pagination>
        )}
        
      </div>
    )
  }
}
export default PaginatedTable;