import React from "react";
import { View } from '@instructure/ui-view'
import { Flex } from '@instructure/ui-flex'
import { CloseButton } from '@instructure/ui-buttons'
import { Heading } from '@instructure/ui-heading'
import { Tray } from '@instructure/ui-tray'
import { Table } from '@instructure/ui-table';
import {Responsive} from '@instructure/ui-responsive';
import { Link } from '@instructure/ui-link'
import { ScreenReaderContent } from '@instructure/ui-a11y-content';
import { Text } from '@instructure/ui-text'

class CourseTable extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      sortBy: 'course_id',
      ascending: true,
    }
  }

  handleSort = (event, { id }) => {
    const { sortBy, ascending } = this.state
    if (id === sortBy) {
      this.setState({
        ascending: !ascending,
      })
    } else {
      this.setState({
        sortBy: id,
        ascending: true,
      })
    }
  }

  renderCellData(id, row, renderCell, layout) {
    if (id === 'num_of_assignments' || id === 'num_of_discussions'){
      return renderCell ? renderCell(row['participation'][id], layout) : row['participation'][id]
    } else if (id === 'avarage_submissions' || id === 'avarage_discussions') {
      var data = 0
      if (id === 'avarage_submissions')
        data = parseInt(row['participation']['num_of_submissions'])/parseInt(row['participation']['num_of_assignments'])
      else
        data = parseInt(row['participation']['num_of_discussions'])/parseInt(row['participation']['num_of_discussions_participants'])
      data = isNaN(data) ? 0 : (data).toFixed(2)
      return renderCell ? renderCell(data, layout) : data
    } else {
      return renderCell ? renderCell(row[id], layout) : row[id]
    }
  }

  renderTable(headers, rows, lms_domain) {
    const { sortBy, ascending } = this.state
    const direction = ascending ? 'ascending' : 'descending'
    const sortedRows = [...rows].sort((a, b) => {
      if (a[sortBy] < b[sortBy]) {
        return -1
      }
      if (a[sortBy] > b[sortBy]) {
        return 1
      }
      return 0
    })

    if (!ascending) {
      sortedRows.reverse()
    }

    return (<Responsive
      query={{
        small: { maxWidth: '40rem' },
        large: { minWidth: '41rem' },
      }}
      props={{
        small: { layout: 'stacked' },
        large: { layout: 'fixed' },
      }}
    >
      {({ layout }) => (
        <div>
          <Table
            layout={layout}
            margin="auto"
            caption={`Sắp xếp theo ${sortBy}`}
            {...layout}
          >
            <Table.Head renderSortLabel={<ScreenReaderContent>Sắp xếp theo</ScreenReaderContent>}>
              <Table.Row>
                {(headers || []).map(({ id, text, width, textAlign }) => (
                  <Table.ColHeader
                    key={id}
                    id={id}
                    width={width}
                    textAlign={textAlign}
                    onRequestSort={this.handleSort}
                    sortDirection={id === sortBy ? direction : 'none'}
                    themeOverride={{
                      fontSize: '0.81rem'
                    }}
                  >
                    {text}
                  </Table.ColHeader>
                 ))}
                 <Table.ColHeader
                    key={lms_domain}
                    id={lms_domain}
                    themeOverride={{
                      fontSize: '0.81rem'
                    }}
                  >Link khóa học</Table.ColHeader>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {sortedRows.map((row) => (
                <Table.Row key={row.id}>
                  {headers.map(({ id, renderCell, textAlign }) => (
                    <Table.Cell
                      key={id}
                      textAlign={layout === 'stacked' ? 'start' : textAlign}
                    >
                      {this.renderCellData(id, row, renderCell, layout)}
                    </Table.Cell>
                  ))}
                  <Table.Cell
                      key={lms_domain}
                      textAlign={'start'}
                    ><Link href={`${lms_domain}/courses/${row['course_id']}`} target="_blank">Xem</Link></Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      )}
    </Responsive>
    )
  }

  render() {
    const { courses, teacher_name, open, onClick, hideTray, lms_domain} = this.props
    return (
      <Tray
      label="Tray"
      open={open}
      onDismiss={onClick}
      size="large"
      placement="end"
    >
      <View as="div" padding="medium">
        <Flex>
          <Flex.Item shouldGrow shouldShrink>
            <Heading>Thống kê khóa học của giáo viên {teacher_name}</Heading>
          </Flex.Item>
          <Flex.Item>
            <CloseButton
              placement="end"
              offset="small"
              screenReaderLabel="Close"
              onClick={hideTray}
            />
          </Flex.Item>
        </Flex>
        <Text fontStyle="italic">(*)Dữ liệu chỉ tính cho những loại nội dung đã công bố</Text>
        { courses.length > 0 ? this.renderTable([
          {
            id: 'course_id',
            text: 'ID',
          },{
            id: "TenMon",
            text: "Tên Môn",
          },{
            id: "TenLop",
            text: "Tên Lớp",
          },{
            id: "num_of_modules",
            text: "Tổng modules*",
          },{
            id: "num_of_items",
            text: "Tổng nội dung* trong các module",
          },{
            id: "num_of_pages",
            text: "Tổng trang*",
          },{
            id: "num_of_assignments",
            text: "Tổng bài tập*",
          },{
            id: "avarage_submissions",
            text: "Số lượng bài nộp TB trên một bài tập",
          },{
            id: "num_of_discussions",
            text: "Tổng thảo luận*",
          },{
            id: "avarage_discussions",
            text: "Số lượng tham gia TB trên một thảo luận",
          }],courses, lms_domain): "Dữ liệu trống"}
        
      </View>
    </Tray>
    )
  }
}

export default CourseTable;