import React from "react";
import { SimpleSelect } from '@instructure/ui-simple-select'
import { Grid } from '@instructure/ui-grid'

class Search extends React.Component {
  constructor (props) {
    super(props)
  }
  render(){
    const { search, searchBy, placeholder, renderDropDown} = this.props
    return (
      <Grid startAt="medium" vAlign="middle" colSpacing="small">
        <Grid.Row>
          <Grid.Col width="auto">
            <SimpleSelect isInline={true} onChange={searchBy}>
              <SimpleSelect.Group renderLabel="Tìm kiếm theo" key="grp1">
                <SimpleSelect.Option id="opt1" value="teacher">
                  Giáo viên
                </SimpleSelect.Option>
                <SimpleSelect.Option id="opt2" value="subject">
                  Môn học
                </SimpleSelect.Option>
              </SimpleSelect.Group>
            </SimpleSelect>
          </Grid.Col>
          <Grid.Col>
            {renderDropDown()}
          </Grid.Col>
        </Grid.Row>
      </Grid>
    )
  }      
}

export default Search;