const locale = {
  totalLesson: "Số khóa học khởi tạo",
  totalVisit: "Số lần truy cập vào hệ thống của giáo viên",
  totalLessonHasCondition: "Số khóa học/bài giảng có thiết lập điều kiện tiên quyết",
  totalComment: "Số lượng chủ đề thảo luận",
  totalCommentReplied: "Số lượng lượt trả lời thảo luận",
  totalQuestionChoice: "Số câu hỏi dạng trắc nghiệm 1 đáp án",
  totalQuestionMultiTrue: "Số câu hỏi dạng nhiều đáp án đúng",
  totalQuestionBool: "Số câu hỏi dạng đúng/sai",
  totalQuestionFill: "Số câu hỏi dạng điền vào chỗ trống",
  totalQuestionReplyFile: "Tập tin / tự luận",
  totalOtherQuestion: "Tổng số câu hỏi khác",
  totalPupilAttemptTest: "Tổng số lượt học sinh làm bài kiểm tra",
  totalParent: "Số lượng phụ huynh được cấp tài khoản truy cập vào ứng dụng/hệ thống",
  totalDocument: "Số lượng tài liệu",
  totalVideo: "Số lượng học liệu video",
  totalAudio: "Số lượng học liệu audio",
  totalImage: "Số lượng hình ảnh",
  totalCoursewareQuestion: "Số lượng câu hỏi",
  totalEmbedContent: "Số lượng học liệu nhúng",
  totalOtherCourseware: "Số lượng các học liệu khác"
}
const t = (key) => {
  return locale[key] ? locale[key] : key;
}

module.exports = {
  t
}