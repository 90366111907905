import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Pages } from "@instructure/ui-pages";
import { Button } from "@instructure/ui-buttons";
import { View } from "@instructure/ui-view";
import { Text } from "@instructure/ui-text";
import { IconArrowStartLine } from "@instructure/ui-icons";

class DTIPages extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activePageIndex: 0,
    };
  }

  handlePagesBackButtonClick = (activePageIndex) => {
    this.setState({
      activePageIndex,
    });
  };

  handleViewDetailsClick = () => {
    this.setState({
      activePageIndex: 1,
    });
  };

  renderBackButton(navigateToPreviousPage) {
    return (
      <Button
        onClick={navigateToPreviousPage}
        renderIcon={IconArrowStartLine}
        screenReaderLabel="Back"
        withBorder={false}
        withBackground={false}
      />
    );
  }

  render() {
    const { firstPage, secondPage } = this.props;
    return (
      <>
        <Pages
          activePageIndex={this.state.activePageIndex}
          onPageIndexChange={this.handlePagesBackButtonClick}
        >
          <Pages.Page>
            {(history, navigateToPreviousPage) => {
              return (
                <div>
                  <View textAlign="end" margin="small" display="block">
                    {history.length > 1 &&
                      this.renderBackButton(navigateToPreviousPage)}
                    <Button
                      onClick={this.handleViewDetailsClick}
                      withBackground={false}
                      color="primary"
                    >
                      Xem chi tiết
                    </Button>
                  </View>
                  <View as="div" margin="small">
                    {firstPage}
                  </View>
                </div>
              );
            }}
          </Pages.Page>
          <Pages.Page>
            {(history, navigateToPreviousPage) => {
              return (
                <div>
                  <View textAlign="end" margin="small" display="block">
                    {history.length > 1 &&
                      this.renderBackButton(navigateToPreviousPage)}
                  </View>

                  <View margin="large">{secondPage}</View>
                </div>
              );
            }}
          </Pages.Page>
        </Pages>
      </>
    );
  }
}

export default DTIPages;
