import React, { useEffect, useState } from "react";
import { Table } from "@instructure/ui-table";
import PaginatedTable from "../common/PaginatedTable";
import DTIPages from "../common/DTIPages";
import { Button } from "@instructure/ui-buttons";
import { View } from "@instructure/ui-view";
import { Text } from "@instructure/ui-text";
import { getJobQuiz, getQuiz, createJobQuiz, postQuiz } from "../api/api";
import { t } from "../ultils/translates";
import { Spinner } from "@instructure/ui-spinner";

export default function Quiz({ caption, iframe = false, layout = "auto" }) {
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [headers, setHeaders] = useState([
    {
      id: "stt",
      text: t("STT"),
      width: "5%",
      textAlign: "start",
    },
    {
      id: "name",
      text: t("Tên khóa học"),
      width: "10%",
      textAlign: "start",
    },
    {
      id: "totalQuestionChoice",
      text: t("totalQuestionChoice"),
      width: "10%",
      textAlign: "center",
    },
    {
      id: "totalQuestionMultiTrue",
      text: t("totalQuestionMultiTrue"),
      width: "10%",
      textAlign: "center",
    },
    {
      id: "totalQuestionBool",
      text: t("totalQuestionBool"),
      width: "10%",
      textAlign: "center",
    },
    {
      id: "totalQuestionFill",
      text: t("totalQuestionFill"),
      width: "10%",
      textAlign: "center",
    },
    {
      id: "totalQuestionReplyFile",
      text: t("totalQuestionReplyFile"),
      width: "10%",
      textAlign: "center",
    },
    {
      id: "totalOtherQuestion",
      text: t("totalOtherQuestion"),
      width: "10%",
      textAlign: "center",
    },
    {
      id: "totalPupilAttemptTest",
      text: t("totalPupilAttemptTest"),
      width: "10%",
      textAlign: "center",
    },
  ]);
  const [rows, setRows] = useState([]);
  const [demoHeaders, setDemoHeaders] = useState([
    {
      id: "totalQuestionChoice",
      text: t("totalQuestionChoice"),
      width: "10%",
      textAlign: "center",
    },
    {
      id: "totalQuestionMultiTrue",
      text: t("totalQuestionMultiTrue"),
      width: "10%",
      textAlign: "center",
    },
    {
      id: "totalQuestionBool",
      text: t("totalQuestionBool"),
      width: "10%",
      textAlign: "center",
    },
    {
      id: "totalQuestionFill",
      text: t("totalQuestionFill"),
      width: "10%",
      textAlign: "center",
    },
    {
      id: "totalQuestionReplyFile",
      text: t("totalQuestionReplyFile"),
      width: "10%",
      textAlign: "center",
    },
    {
      id: "totalOtherQuestion",
      text: t("totalOtherQuestion"),
      width: "10%",
      textAlign: "center",
    },
    {
      id: "totalPupilAttemptTest",
      text: t("totalPupilAttemptTest"),
      width: "10%",
      textAlign: "center",
    },
  ]);
  const [demoRows, setDemoRows] = useState([]);
  const [ascending, setAscending] = useState("ascending");
  useEffect(() => {
    const fetchData = async () => {
      const groupedData = {};
      const dataQuiz = await getQuiz();
      setDemoRows([
        {
          totalQuestionChoice: dataQuiz.totalQuestionChoice,
          totalQuestionMultiTrue: dataQuiz.totalQuestionMultiTrue,
          totalQuestionBool: dataQuiz.totalQuestionBool,
          totalQuestionFill: dataQuiz.totalQuestionFill,
          totalQuestionReplyFile: dataQuiz.totalQuestionReplyFile,
          totalOtherQuestion: dataQuiz.totalOtherQuestion,
          totalPupilAttemptTest: dataQuiz.totalPupilAttemptTest,
        },
      ]);
      function addToGroupedData(item, key) {
        const { id, name, count } = item;
        if (!groupedData[id]) {
          groupedData[id] = {
            stt: Object.keys(groupedData).length + 1,
            id,
            name,
            totalQuestionChoice: 0,
            totalQuestionMultiTrue: 0,
            totalQuestionBool: 0,
            totalQuestionFill: 0,
            totalQuestionReplyFile: 0,
            totalOtherQuestion: 0,
            totalPupilAttemptTest: 0,
          };
        }
        groupedData[id][key] += parseInt(count, 10);
      }

      dataQuiz.dataQuestionChoice.forEach((item) => {
        addToGroupedData(item, "totalQuestionChoice");
      });

      // Process dataQuestionMultiTrue
      dataQuiz.dataQuestionMultiTrue.forEach((item) => {
        addToGroupedData(item, "totalQuestionMultiTrue");
      });

      // Process dataQuestionBool
      dataQuiz.dataQuestionBool.forEach((item) => {
        addToGroupedData(item, "totalQuestionBool");
      });

      // Process dataQuestionFill
      dataQuiz.dataQuestionFill.forEach((item) => {
        addToGroupedData(item, "dataQuestionFill");
      });

      // Process dataQuestionReplyFile
      dataQuiz.dataQuestionReplyFile.forEach((item) => {
        addToGroupedData(item, "totalQuestionReplyFile");
      });

      // Process dataPupilAttemptTest
      dataQuiz.dataPupilAttemptTest.forEach((item) => {
        addToGroupedData(item, "totalOtherQuestion");
      });

      // Process dataOtherQuestion
      dataQuiz.dataOtherQuestion.forEach((item) => {
        addToGroupedData(item, "totalPupilAttemptTest");
      });
      setRows(Object.values(groupedData));
      const job = await getJobQuiz();
      if (["starting", "pending"].includes(job?.workflow_state)) {
        setIsLoading(true);
        var intervalId = setInterval(async () => {
          const job = await getJobQuiz();
          if (job?.workflow_state === "done") {
            setIsLoading(false);
            clearInterval(intervalId);
          }
        }, 1000);
      }
    };
    fetchData();
  }, [isLoading]);
  const handleSort = (event, options) => {
    const { onSort } = this.state;

    this.setState({
      page: 0,
    });
    onSort(event, options);
  };

  const handleSubmit = async () => {
    setIsSubmit(true);
    const data = await postQuiz();
    setIsSubmit(false);
    setSuccess(true);
    const date = new Date(data.updatedAt);

    const options = {
      timeZone: "Asia/Ho_Chi_Minh",
      hour: "2-digit",
      minute: "2-digit",
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour12: false, // Use 24-hour format
    };

    // Use Intl.DateTimeFormat to format the date according to the specified options
    const formatter = new Intl.DateTimeFormat("en-GB", options);
    const [
      { value: day }, // Skips the comma
      ,
      { value: month }, // Skips the comma
      ,
      { value: year }, // Skips the comma
      ,
      { value: hours }, // Skips the comma
      ,
      { value: minutes },
    ] = formatter.formatToParts(date);

    // Format the date as HH:mm DD/MM/YYYY
    const formattedDate = `${hours}:${minutes} ${day}/${month}/${year}`;
    setMessage(`vào ${formattedDate}`);
  };
  const handleJob = async () => {
    if (!isLoading) {
      setIsLoading(true);
      await createJobQuiz();
      var intervalId = setInterval(async () => {
        const job = await getJobQuiz();
        if (job?.workflow_state === "done") {
          setIsLoading(false);
          clearInterval(intervalId);
        }
      }, 1000);
    }
  };
  return (
    <>
      {iframe ? (
        <>
          <PaginatedTable
            headers={headers}
            rows={rows}
            sortBy={headers && headers[0]?.name}
            onSort={handleSort}
            ascending={ascending}
            perPage={15}
            layout={layout}
          />
        </>
      ) : (
        <>
          <View as="div" display="inline-block" textAlign="end">
            <Button
              onClick={handleSubmit}
              withBackground={false}
              color="primary"
              size="small"
              margin="small"
            >
              {isSubmit ? (
                <Spinner renderTitle="Đang tải" size="x-small" />
              ) : (
                "Nộp báo cáo DTI"
              )}
            </Button>
            <Text>
              {success ? (
                <>
                  <b style={{ color: "green" }}>Đã nộp</b> vào {message}
                </>
              ) : (
                <></>
              )}
            </Text>
          </View>
          <View as="div">
            <Text size="medium">
              Dữ liệu trước khi nộp báo cáo DTI cho <b>{caption}</b>
            </Text>
            <Button
              onClick={handleJob}
              withBackground={false}
              color="primary"
              size="small"
              margin="small"
            >
              {isLoading ? (
                <Spinner renderTitle="Đang tải" size="x-small" />
              ) : (
                "Làm mới"
              )}
            </Button>
          </View>

          <DTIPages
            secondPage={
              <PaginatedTable
                headers={headers}
                rows={rows}
                sortBy={headers && headers[0]?.id}
                onSort={handleSort}
                ascending={ascending}
                perPage={10}
              />
            }
            firstPage={
              <Table caption={caption}>
                <Table.Head>
                  <Table.Row>
                    {(demoHeaders || []).map(
                      ({ id, text, width, textAlign }) => (
                        <Table.ColHeader
                          key={id}
                          id={id}
                          width={width}
                          textAlign={textAlign}
                        >
                          {text}
                        </Table.ColHeader>
                      )
                    )}
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  {demoRows.map((row) => (
                    <Table.Row key={row.id}>
                      {demoHeaders.map(({ id, renderCell, textAlign }) => (
                        <Table.Cell
                          key={id}
                          textAlign={layout === "stacked" ? "start" : textAlign}
                        >
                          {renderCell ? renderCell(row[id]) : row[id]}
                        </Table.Cell>
                      ))}
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            }
          />
        </>
      )}
    </>
  );
}
