import React from "react";
import PaginatedTable from './PaginatedTable'

class SortableTable extends React.Component {
  constructor (props) {
    super(props)
    const { headers } = props

    this.state = {
      sortBy: headers && headers[0] && headers[0].id,
      ascending: true,
    }
  }

  handleSort = (event, { id }) => {
    const { sortBy, ascending } = this.state

    if (id === sortBy) {
      this.setState({
        ascending: !ascending,
      })
    } else {
      this.setState({
        sortBy: id,
        ascending: true,
      })
    }
  }

  render() {
    const { caption, headers, rows, perPage, lms_domain } = this.props
    const { sortBy, ascending } = this.state
    const sortedRows = [...rows].sort((a, b) => {
      if (a[sortBy] < b[sortBy]) {
        return -1
      }
      if (a[sortBy] > b[sortBy]) {
        return 1
      }
      return 0
    })

    if (!ascending) {
      sortedRows.reverse()
    }
    return (
      <div>
        <PaginatedTable
          caption={caption}
          headers={headers}
          rows={sortedRows}
          onSort={this.handleSort}
          sortBy={sortBy}
          ascending={ascending}
          perPage={perPage}
          lms_domain={lms_domain}
        />
      </div>
    )
  }
}
export default SortableTable;
