
import React from 'react'
import SortableTable from './components/SortableTable'
import { View } from '@instructure/ui-view'
import { Text } from '@instructure/ui-text'
import Export from './components/Export'
import { Flex } from '@instructure/ui-flex'
import './App.css';
import Search from './components/Search'
import { SimpleSelect } from '@instructure/ui-simple-select'
import { TextInput } from '@instructure/ui-text-input'
import { Tabs } from '@instructure/ui-tabs'
import ShowDTI from "./components/dti/ShowDTI"

function timeToHuman (updated_at){
  return (function(time) {
    var a = (new Date(time)).toString().split(/\s/);
    return a[2] + "/" + {
        Jan: "01",
        Feb: "02",
        Mar: "03",
        Apr: "04",
        May: "05",
        Jun: "06",
        Jul: "07",
        Aug: "08",
        Sep: "09",
        Oct: "10",
        Nov: "11",
        Dec: "12"
    }[a[1]] + "/" + a[3] + " " + a[4]
  }(new Date(updated_at)));
}

function App() {
  const [data, setData] = React.useState(null);
  const [subjects, setSubjects] = React.useState(null);
  const [value_subject, setValueSubjects] = React.useState('');

  const [lms_domain, setLmsDomain] = React.useState(null);
  
  const [search_data, setSearchData] = React.useState(null);
  const [search_string, setSearchString] = React.useState("");
  const [search_by, setSearchBy] = React.useState('teacher');
  const [placeholder, setPlaceHolder] = React.useState("Tìm kiếm theo tên giáo viên");
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleTabChange = (event, { index, id }) => {
    setSelectedIndex(index)
  }
  
  React.useEffect(() => {
    fetch("/api/v1/session")
      .then((res) => res.json())
      .then((data) => {
        setLmsDomain(data.custom_domain)
        fetch(`/api/v1/teachers?custom_root_account_uuid=${encodeURIComponent(data.custom_root_account_uuid)}&custom_canvas_account_id=${encodeURIComponent(data.custom_canvas_account_id)}`)
        .then((res) => res.json())
        .then((data) => {
          setData(data.data);
          setSubjects(data.subjects)
        })
      });
  }, []);
  
  function search(event, value) {
    value = value.toLowerCase()
    setSearchString(value);
    if (search_by == 'teacher') {
      var search_data = data.filter(word => word["GiaoVienID"].toLowerCase().includes(value) || word["name"].toLowerCase().includes(value))
      setSearchData(search_data);
    } else {
      var search_data = data.filter(word => word['courses'].find(course => course['TenMon'].toLowerCase().includes(value) > 0))
      setSearchData(search_data);
    }
  }

  function search_filter(by, value) {
    value = value.toLowerCase()
    setSearchString(value);
    if (by == 'teacher') {
      var search_data = data.filter(word => word["GiaoVienID"].toLowerCase().includes(value) || word["name"].toLowerCase().includes(value))
      setSearchData(search_data);
    } else {
      var search_data = data.filter(word => word['courses'].find(course => course['TenMon'].toLowerCase().includes(value) > 0))
      setSearchData(search_data);
    }
  }

  function searchBy(event, data) {
    if (data.value == 'teacher') {
      setPlaceHolder("Tìm kiếm theo tên giáo viên")
      search_filter(data.value, '');
    } else {
      if (value_subject == '')
        search_filter(data.value, subjects[0]);
      else
        search_filter(data.value, value_subject);
    }
    setSearchBy(data.value);
  }

  function handleSelect (e, { id, value }) {
    setValueSubjects(value)
    console.log(value)
    var search_data = data.filter(word => word['courses'].find(course => course['TenMon'] == value))
    setSearchData(search_data);
  }

  function renderDropDown() {
    if (search_by == 'teacher') {
      return (
        <TextInput placeholder={placeholder}
                onChange={search}/>
      )
    } else {
      return (
        <SimpleSelect
          value={value_subject}
          onChange={handleSelect}
        >
          {subjects.map((opt, index) => (
            <SimpleSelect.Option
              key={index}
              id={`opt-${index}`}
              value={opt}
            >
              { opt }
            </SimpleSelect.Option>
          ))}

        </SimpleSelect>
      )
    }
    
  }

  return (
    <View
        as="div"
        margin="small"
        padding="small"
        background="primary"
      >
        <Tabs
        margin="small auto"
        padding="small"
        onRequestTabChange={handleTabChange}
      >
        <Tabs.Panel
          id="tkhd"
          renderTitle="Thống kê hoạt động"
          textAlign="center"
          padding="large"
          isSelected={selectedIndex === 0}
        >
          <Export/>
          <Text>Cập nhật lần cuối: {!data ? "Loading..." : data.length > 0 ? timeToHuman(data[0].updated_at) : "Dữ liệu trống"}</Text>
          <Search search={search} searchBy={searchBy} placeholder={placeholder} renderDropDown={renderDropDown}/>
          {!data ? <></> : data.length <= 0 ? 
          <Flex justifyItems="center" margin="large">
            <Flex.Item>
            <Text>Không có dữ liệu</Text>
            </Flex.Item>
          </Flex> :
          <SortableTable
            headers={[
              {
                id: 'GiaoVienID',
                text: 'GiaoVienID',
              },
              {
                id: 'name',
                text: 'Họ và tên',
                width: '40%',
              },
              {
                id: 'num_of_modules',
                text: 'Tổng module đã công bố',
              },
              {
                id: 'num_of_items',
                text: 'Tổng nội dung đã công bố',
              }
            ]}
            rows={search_data ? search_data : data}
            perPage={10}
            lms_domain={lms_domain}
          />}
        </Tabs.Panel>
        <Tabs.Panel id="dti-tab" renderTitle="Báo cáo DTI" isSelected={selectedIndex === 1}>
          <ShowDTI />
        </Tabs.Panel>
      </Tabs>
    </View>
  );
}

export default App;
